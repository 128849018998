function About() {
  return (
    <div className="about">
      <h1>À propos</h1>
      <div className="about__chapo">
        Basket Playground est une plateforme collaborative qui veut faciliter l'accès aux playgrounds.
      </div>
      <p className="about__text">
        À l’heure actuelle, Basket Playground est limité aux terrains parisiens mais va progressivement s’ouvrir à d’autres territoires. Des fonctionnalités supplémentaires sont actuellement en développement, stay tuned...
      </p>
      <div className="about__links">
        <a href="https://instagram.com/bp" className="about__link">Instagram</a>
        <a href="mailto:contact@bp.com" className="about__link">Contact</a>
      </div>
    </div>
  );
}

export default About;