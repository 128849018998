import React, { useState, useEffect } from "react";
import loc from "./assets/icons/loc.svg";
import styles from "./PlaygroundDetails.module.css";

function PlaygroundDetails({ playground, index }) {
  const [showFullSchedule, setShowFullSchedule] = useState(false);

  // Reset showFullSchedule when a new playground is selected
  useEffect(() => {
    setShowFullSchedule(false);
  }, [playground, index]);

  const toggleSchedule = () => setShowFullSchedule(!showFullSchedule);

  const extractDepartmentCode = (address) => {
    if (!address) {
      return "75"; // Default to 75 if address is undefined
    }

    const postalCodeMatch = address.match(/\b(\d{5})\b/); // Match a sequence of exactly five digits

    if (postalCodeMatch) {
      const departmentCode = postalCodeMatch[1].substring(0, 2);
      return departmentCode;
    } else {
      return "75"; // Default to 75 if no match is found
    }
  };

  const formatPlaygroundId = (index, address) => {
    const departmentCode = extractDepartmentCode(address);
    if (typeof index !== "number") {
      return `BP-XXX-${departmentCode}`; // Provide a default or error code
    }
    const formattedId = `BP-${index
      .toString()
      .padStart(3, "0")}-${departmentCode}`;
    return formattedId;
  };

  const renderMarqueeStatus = () => {
    if (playground.isOpen247) {
      return "Ouvert 24/24h";
    }

    const now = new Date();
    const dayNames = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const todayName = dayNames[now.getDay()];
    const currentTime = now.getHours() + now.getMinutes() / 60;

    // Initialize message as closed
    let message = "Fermé pour aujourd'hui";

    playground.schedule.forEach((period) => {
      const startDayIndex = dayNames.indexOf(period.startDay);
      const endDayIndex = dayNames.indexOf(period.endDay || period.startDay);
      const todayIndex = dayNames.indexOf(todayName);

      if (
        (startDayIndex <= endDayIndex &&
          todayIndex >= startDayIndex &&
          todayIndex <= endDayIndex) ||
        (startDayIndex > endDayIndex &&
          (todayIndex >= startDayIndex || todayIndex <= endDayIndex))
      ) {
        const sortedBreaks = period.breaks.sort(
          (a, b) =>
            parseFloat(a.start.split(":").join(".")) -
            parseFloat(b.start.split(":").join("."))
        );

        sortedBreaks.forEach((breakTime) => {
          const breakStart = parseFloat(
            breakTime.start
              .split(":")
              .reduce(
                (acc, time, idx) => acc + parseInt(time, 10) / (idx ? 60 : 1),
                0
              )
          );
          const breakEnd = parseFloat(
            breakTime.end
              .split(":")
              .reduce(
                (acc, time, idx) => acc + parseInt(time, 10) / (idx ? 60 : 1),
                0
              )
          );

          if (currentTime >= breakStart && currentTime < breakEnd) {
            message = `Ouvert jusqu'à ${breakTime.end}`;
          } else if (currentTime < breakStart) {
            message = `Ouvre à ${sortedBreaks[0].start}`;
          }
        });
      }
    });

    return message;
  };

  function renderInfoDetails() {
    const details = [];
    if (playground.surfaceType) details.push(playground.surfaceType);
    if (playground.halfCourts > 0)
      details.push(
        `${playground.halfCourts} demi-terrain${
          playground.halfCourts > 1 ? "s" : ""
        }`
      );
    if (playground.fullCourts > 0)
      details.push(
        `${playground.fullCourts} terrain${
          playground.fullCourts > 1 ? "s entiers" : " entier"
        }`
      );
    if (playground.covered)
      details.push(playground.covered ? "Couvert" : "Découvert");
    if (playground.additionalInfo) details.push(playground.additionalInfo);

    return details.join(", ");
  }

  const getNextBreakTime = () => {
    if (playground.isOpen247) {
      return "Ouvert 24/24h";
    }

    const now = new Date();
    const currentTime = now.getHours() + now.getMinutes() / 60;
    const dayNames = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const todayIndex = now.getDay();

    // Function to convert time string to decimal hours
    const timeToDecimal = (time) => {
      const [hours, minutes] = time.split(":").map(parseFloat);
      return hours + minutes / 60;
    };

    let currentBreak = null;
    let nextBreak = null;

    // Iterate over the days of the week starting from today
    for (let i = 0; i < dayNames.length; i++) {
      const dayIndex = (todayIndex + i) % dayNames.length;
      const dayName = dayNames[dayIndex];

      const todaysSchedule = playground.schedule.find((period) => {
        const startDayIndex = dayNames.indexOf(period.startDay);
        const endDayIndex = period.endDay
          ? dayNames.indexOf(period.endDay)
          : startDayIndex;
        return startDayIndex <= endDayIndex
          ? dayIndex >= startDayIndex && dayIndex <= endDayIndex
          : dayIndex >= startDayIndex || dayIndex <= endDayIndex;
      });

      if (todaysSchedule && todaysSchedule.breaks) {
        for (let breakTime of todaysSchedule.breaks) {
          const breakStart = timeToDecimal(breakTime.start);
          const breakEnd = timeToDecimal(breakTime.end);

          // If it's today, check if the current time is within this break
          if (i === 0 && currentTime >= breakStart && currentTime < breakEnd) {
            currentBreak = `${breakTime.start} - ${breakTime.end}`;
            break; // Exit if a current break is found
          }

          // If no current break is found, find the next upcoming break
          if (
            !currentBreak &&
            (!nextBreak || breakStart < timeToDecimal(nextBreak.start))
          ) {
            nextBreak = breakTime; // Store the earliest next break found
          }
        }
      }

      // If a current break has been found, stop the search
      if (currentBreak) break;
    }

    // Return the current break or the next break time if no current break is ongoing
    if (currentBreak) {
      return currentBreak;
    } else if (nextBreak) {
      return `${nextBreak.start} - ${nextBreak.end}`;
    } else {
      return "Pas de pause prévue."; // If no breaks are found at all
    }
  };

  const formatAddress = (address) => {
    const parts = address.split(", ");
    parts.pop(); // Remove the last element, typically the country
    return parts.join(", "); // Join the remaining parts back into a string
  };

  const marqueeInfo = renderMarqueeStatus(); // get marquee info including open status
  const nextBreakTime = getNextBreakTime();

  return (
    <div className="playground-details">
      <h2 className={styles.h2}>
        {playground.name}
        {playground.nickname && ` AKA ${playground.nickname}`}
      </h2>
      <p className={styles.code}>{formatPlaygroundId(index, playground.address)}</p>
      <div
        className={`${styles.marqueeStatus} ${
          marqueeInfo.includes("Ouvert") ? styles.open : styles.closed
        }`}
      >
        <div>
          <span>{marqueeInfo}</span>
          <span>{marqueeInfo}</span>
          <span>{marqueeInfo}</span>
          <span>{marqueeInfo}</span>
        </div>
      </div>
      <div className={styles.scheduleSection}>
        <h3 className={styles.scheduleSection__title}>Horaires</h3>
        {!playground.isOpen247 && !showFullSchedule && (
          <div onClick={toggleSchedule} className={styles.toggleButton}>
            <span className={styles.toggleButtonSpan}></span>{" "}
            {/* First line of the arrow */}
            <span className={styles.toggleButtonSpan}></span>{" "}
            {/* Second line of the arrow */}
          </div>
        )}
        {!playground.isOpen247 && !showFullSchedule && (
          <p className={styles.nextBreakTime}>{nextBreakTime}</p>
        )}
        {!playground.isOpen247 && showFullSchedule && (
          <div className={styles.periodWrapper}>
            {playground.schedule.map((period, idx) => (
              <div className={styles.period} key={idx}>
                {period.startDay}
                {period.endDay && period.endDay !== period.startDay
                  ? ` - ${period.endDay}`
                  : ""}
                {period.breaks && period.breaks.length > 0 && (
                  <ul className={styles.period__ul}>
                    {period.breaks.map((breakTime, breakIdx) => (
                      <li key={breakIdx}>
                        {breakTime.start} - {breakTime.end}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        )}
        {playground.isOpen247 && (
          <p className={styles.nextBreakTime}>Ouvert 24/24h</p>
        )}
      </div>
      <a
        className={styles.address}
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          playground.latitude
        )},${encodeURIComponent(playground.longitude)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={loc} className="pin" alt="pin icon" />
        <p className={styles.address__name}>
          {formatAddress(playground.address)}
        </p>
      </a>
      <div className={styles.infos}>
        <h3 className={styles.infos__title}>Infos</h3>
        <p className={styles.infos__paragraph}>{renderInfoDetails()}</p>
      </div>
    </div>
  );
}

export default PlaygroundDetails;
