import React, { useState, useCallback, useRef } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import OpeningHoursForm from "./OpeningHoursForm"; // Adjust the path as necessary
import useRadioSelection from "./useRadioSelection";
import IncrementDecrementInput from "./IncrementDecrementInput"; // Make sure the path is correct

function PlaygroundForm() {
  const surfaceTypeHook = useRadioSelection("Béton");
  const coveredHook = useRadioSelection("Couvert");

  const [formState, setFormState] = useState({
    name: "",
    nickname: "",
    address: "",
    latitude: "",
    longitude: "",
    halfCourts: 0,
    fullCourts: 0,
    surfaceType: "",
    covered: "",
    additionalInfo: "",
    schedule: {},
    isOpen247: false,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDa6fpfYDkoDMqtXen14ZLNrUYkIRs_k04",
    libraries: ["places"],
  });

  const autocompleteRef = useRef(null);

  const onPlaceChanged = () => {
    if (autocompleteRef.current && autocompleteRef.current.getPlace()) {
      const place = autocompleteRef.current.getPlace();
      setFormState({
        ...formState,
        address: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
    }
  };

  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Ensure pseudo-elements update correctly on first click
    if (name === "surfaceType") {
      surfaceTypeHook.handleRadioChange(event);
    } else if (name === "covered") {
      coveredHook.handleRadioChange(event);
    }
  };

  const handleScheduleUpdate = useCallback((updatedSchedule) => {
    setFormState((prevState) => ({
      ...prevState,
      schedule: updatedSchedule,
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let newErrors = {};
    // Check required fields
    if (!formState.name) {
      newErrors.name = "Name is required";
    }
    if (!formState.address) {
      newErrors.address = "Address is required";
    }
    if (formState.halfCourts <= 0 && formState.fullCourts <= 0) {
      newErrors.courts = "At least one type of court must be above 0";
    }

    setErrors(newErrors);
    setShowSuccessMessage(false);

    // If there are any errors, stop the form submission
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    try {
      console.log("Submitting formState:", formState); // Log formState
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/playgrounds`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formState),
        }
      );
      if (!response.ok) throw new Error("Network response was not ok.");

      // Handle success
      setShowSuccessMessage(true); // Show success message
      // Consider resetting the form here if needed
      // setFormState({...inheritFormState});
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <>
      <h1>Ajouter un terrain</h1>
      {showSuccessMessage ? (
        <div className="successful">
          Merci pour ta participation. Apres validation de l'equipe de
          moderation, le terrain sera disponible sur la carte.
        </div>
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__row">
            <input
              className="form__input form__input--name"
              type="text"
              name="name"
              placeholder="Nom du terrain"
              value={formState.name}
              onChange={handleChange}
              style={{ borderColor: errors.name ? "red" : "inherit" }}
            />

            <div>aka</div>
            {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
            <input
              className="form__input form__input--nickname"
              type="text"
              name="nickname"
              placeholder="Son surnom"
              value={formState.nickname}
              onChange={handleChange}
            />
          </div>
          {errors.address && (
            <div style={{ color: "red" }}>{errors.address}</div>
          )}
          {isLoaded && (
            <Autocomplete
              onLoad={(autocomplete) =>
                (autocompleteRef.current = autocomplete)
              }
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                name="address"
                value={formState.address}
                onChange={handleChange}
                placeholder="Adresse du terrain"
                className="form__input form__input--address"
              />
            </Autocomplete>
          )}
          {errors.fullCourts && (
            <div style={{ color: "red" }}>{errors.fullCourts}</div>
          )}
          <div className="form__row form__row--increment">
            <IncrementDecrementInput
              label="Demi-terrain"
              name="halfCourts"
              value={formState.halfCourts}
              onChange={handleChange}
              error={errors.halfCourts}
            />
            <div className="form__increment--separator"></div>
            <IncrementDecrementInput
              label="Terrain entier"
              name="fullCourts"
              value={formState.fullCourts}
              onChange={handleChange}
              error={errors.fullCourts}
            />
          </div>
          <div
            className="form__row form__row--radio"
            ref={surfaceTypeHook.formRowRef}
          >
            <label className="form__radio__label">
              <input
                type="radio"
                name="surfaceType"
                value="Béton"
                onChange={(e) => {
                  handleChange(e);
                  surfaceTypeHook.handleRadioChange(e);
                }}
                defaultChecked
              />
              <span>Béton</span>
            </label>
            <label className="form__radio__label">
              <input
                type="radio"
                name="surfaceType"
                value="Gazon"
                onChange={(e) => {
                  handleChange(e);
                  surfaceTypeHook.handleRadioChange(e);
                }}
              />
              <span>Gazon</span>
            </label>
            <label className="form__radio__label">
              <input
                type="radio"
                name="surfaceType"
                value="Parquet"
                onChange={(e) => {
                  handleChange(e);
                  surfaceTypeHook.handleRadioChange(e);
                }}
              />
              <span>Parquet</span>
            </label>
            <label className="form__radio__label">
              <input
                type="radio"
                name="surfaceType"
                value="Autre"
                onChange={(e) => {
                  handleChange(e);
                  surfaceTypeHook.handleRadioChange(e);
                }}
              />
              <span>Autre</span>
            </label>
          </div>
          <div
            className="form__row form__row--radio"
            ref={coveredHook.formRowRef}
          >
            <label className="form__radio__label">
              <input
                type="radio"
                name="covered"
                value="Découvert"
                onChange={(e) => {
                  handleChange(e);
                  coveredHook.handleRadioChange(e);
                }}
                defaultChecked
              />
              <span>Découvert</span>
            </label>
            <label className="form__radio__label">
              <input
                type="radio"
                name="covered"
                value="Couvert"
                onChange={(e) => {
                  handleChange(e);
                  coveredHook.handleRadioChange(e);
                }}
              />
              <span>Couvert</span>
            </label>
          </div>
          <label>
            <textarea
              name="additionalInfo"
              value={formState.additionalInfo}
              onChange={handleChange}
              placeholder="Informations complémentaires"
            />
          </label>
          <br />
          <div className="form__row form__row--checkbox">
            <input
              className="form__checkbox__input"
              type="checkbox"
              name="isOpen247"
              checked={formState.isOpen247}
              onChange={handleChange}
            />
            <label className="form__checkbox__label">Open 24/7</label>
          </div>
          {!formState.isOpen247 && (
            <OpeningHoursForm onUpdateSchedule={handleScheduleUpdate} />
          )}
          {showSuccessMessage && (
            <div className="successful">
              Merci pour ta participation apres validation de notre equipe de
              moderation, le terrain sera disponible sur la carte
            </div>
          )}
          <button className="submit" type="submit">
            Envoyer le terrain
          </button>
        </form>
      )}
    </>
  );
}

export default PlaygroundForm;
