import React, { useState, useEffect } from "react";

const daysOfWeek = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const defaultPeriod = {
  startDay: "Lundi",
  endDay: "Dimanche",
  open: "08:00",
  close: "20:00",
  breaks: [{ start: "08:00", end: "20:00" }],
};

function OpeningHoursForm({ onUpdateSchedule }) {
  const [periods, setPeriods] = useState([defaultPeriod]);

  const handleAddPeriod = () => {
    setPeriods([
      ...periods,
      {
        ...defaultPeriod,
        startDay: "",
        endDay: "",
        breaks: [{ start: "12:00", end: "13:00" }],
      },
    ]);
  };

  const handleAddBreak = (periodIndex) => {
    const updatedPeriods = [...periods];
    updatedPeriods[periodIndex].breaks.push({ start: "", end: "" });
    setPeriods(updatedPeriods);
  };

  const handleBreakChange = (periodIndex, breakIndex, field, value) => {
    const updatedPeriods = [...periods];
    updatedPeriods[periodIndex].breaks[breakIndex][field] = value;
    setPeriods(updatedPeriods);
  };

  const handleDeleteBreak = (periodIndex, breakIndex) => {
    const updatedPeriods = [...periods];
    if (breakIndex !== 0 || periods[periodIndex].breaks.length > 1) {
      updatedPeriods[periodIndex].breaks.splice(breakIndex, 1);
      setPeriods(updatedPeriods);
    }
  };

  const handleDeletePeriod = (periodIndex) => {
    if (periods.length > 1) {
      const updatedPeriods = periods.filter(
        (_, index) => index !== periodIndex
      );
      setPeriods(updatedPeriods);
    }
  };

  const handleDayChange = (periodIndex, field, value) => {
    const updatedPeriods = [...periods];
    updatedPeriods[periodIndex][field] = value;
    setPeriods(updatedPeriods);
  };

  const removeEndDay = (periodIndex) => {
    const updatedPeriods = [...periods];
    updatedPeriods[periodIndex].endDay = "";
    setPeriods(updatedPeriods);
  };

  const addEndDay = (periodIndex) => {
    const updatedPeriods = [...periods];
    updatedPeriods[periodIndex].endDay = "Dimanche";
    setPeriods(updatedPeriods);
  };

  useEffect(() => {
    onUpdateSchedule(periods);
  }, [periods, onUpdateSchedule]);

  return (
    <div>
      {periods.map((period, periodIndex) => (
        <div key={periodIndex} className="period">
          <div className="period-header">
            <span className="period-day">
              <span className="day-label">DU</span>
              <select
                value={period.startDay}
                onChange={(e) =>
                  handleDayChange(periodIndex, "startDay", e.target.value)
                }
              >
                {daysOfWeek.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </span>
            {period.endDay && (
              <span className="period-day">
                <span className="day-label">AU</span>
                <select
                  value={period.endDay}
                  onChange={(e) =>
                    handleDayChange(periodIndex, "endDay", e.target.value)
                  }
                >
                  {daysOfWeek.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </span>
            )}
            {!period.endDay && (
              <button
                type="button"
                onClick={() => addEndDay(periodIndex)}
                className="add-end-day-button plus"
              >
                +
              </button>
            )}
            {period.endDay && (
              <button
                type="button"
                onClick={() => removeEndDay(periodIndex)}
                className="remove-end-day-button minus"
              >
                -
              </button>
            )}
            {periodIndex !== 0 && (
              <button
                type="button"
                onClick={() => handleDeletePeriod(periodIndex)}
                className="remove-period-button"
              >
                Supprimer
              </button>
            )}
          </div>

          {period.breaks.map((breakTime, breakIndex) => (
            <div key={`break-${breakIndex}`} className="break">
              <label className="break-time">
                <span className="time-label">DE</span>
                <input
                  type="time"
                  value={breakTime.start}
                  onChange={(e) =>
                    handleBreakChange(
                      periodIndex,
                      breakIndex,
                      "start",
                      e.target.value
                    )
                  }
                />
              </label>
              <label className="break-time">
                <span className="time-label">À</span>
                <input
                  type="time"
                  value={breakTime.end}
                  onChange={(e) =>
                    handleBreakChange(
                      periodIndex,
                      breakIndex,
                      "end",
                      e.target.value
                    )
                  }
                />
              </label>
              {breakIndex !== 0 && (
                <button
                  type="button"
                  onClick={() => handleDeleteBreak(periodIndex, breakIndex)}
                  className="remove-break-button minus"
                >
                  -
                </button>
              )}
            </div>
          ))}

          <button
            type="button"
            onClick={() => handleAddBreak(periodIndex)}
            className="add-break-button plus"
          >
            +
          </button>
        </div>
      ))}

      <button
        type="button"
        onClick={handleAddPeriod}
        className="add-period-button plus"
      >
        Ajouter un créneau horaire
      </button>
    </div>
  );
}

export default OpeningHoursForm;
