import { useState, useEffect, useRef } from "react";

const useRadioSelection = (initialValue) => {
  const [selectedRadio, setSelectedRadio] = useState(initialValue);
  const formRowRef = useRef(null);

  useEffect(() => {
    if (formRowRef.current) {
      updatePseudoElementStyles();
    }
  }, [selectedRadio]);

  const updatePseudoElementStyles = () => {
    if (formRowRef.current) {
      const radios = formRowRef.current.querySelectorAll('input[type="radio"]');
      const labels = formRowRef.current.querySelectorAll("label");

      radios.forEach((radio, index) => {
        if (radio.checked) {
          const label = labels[index];
          const labelRect = label.getBoundingClientRect();
          const formRowRect = formRowRef.current.getBoundingClientRect();

          // Calculate position and width for the pseudo-element
          const left = labelRect.left - formRowRect.left;
          const width = labelRect.width;

          // Update pseudo-element style
          formRowRef.current.style.setProperty("--pseudo-left", `${left}px`);
          formRowRef.current.style.setProperty("--pseudo-width", `${width}px`);
        }
      });
    }
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
    updatePseudoElementStyles();
  };

  return { formRowRef, handleRadioChange };
};

export default useRadioSelection;
