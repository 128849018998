import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import favicon from "./assets/icons/favicon.svg";
import faviconDev from "./assets/icons/favicon_dev.svg";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Function to dynamically set favicon
function setFavicon() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const faviconPath = env === "dev" ? faviconDev : favicon;
  const link = document.getElementById("favicon");
  if (link) {
    link.href = faviconPath;
  }
}

setFavicon();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
