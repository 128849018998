import React, { useState, useEffect } from "react";

const daysOfWeek = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const ModerationPage = () => {
  const [playgrounds, setPlaygrounds] = useState([]);

  useEffect(() => {
    const fetchPlaygrounds = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/playgrounds`
        );
        const data = await response.json();
        console.log("Fetched playgrounds:", data);
        setPlaygrounds(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching playgrounds:", error);
        setPlaygrounds([]);
      }
    };

    fetchPlaygrounds();
  }, []);

  const handlePlaygroundChange = (e, playgroundId, field) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(
      `Changing field ${field} of playground ${playgroundId} to ${value}`
    );
    setPlaygrounds((prevPlaygrounds) =>
      prevPlaygrounds.map((pg) =>
        pg._id === playgroundId ? { ...pg, [field]: value } : pg
      )
    );
  };

  const handleScheduleChange = (playgroundId, index, field, value) => {
    setPlaygrounds((prevPlaygrounds) =>
      prevPlaygrounds.map((pg) =>
        pg._id === playgroundId
          ? {
              ...pg,
              schedule: pg.schedule.map((period, i) =>
                i === index ? { ...period, [field]: value } : period
              ),
            }
          : pg
      )
    );
  };

  const handleBreakChange = (
    playgroundId,
    periodIndex,
    breakIndex,
    field,
    value
  ) => {
    setPlaygrounds((prevPlaygrounds) =>
      prevPlaygrounds.map((pg) =>
        pg._id === playgroundId
          ? {
              ...pg,
              schedule: pg.schedule.map((period, i) =>
                i === periodIndex
                  ? {
                      ...period,
                      breaks: period.breaks.map((breakTime, j) =>
                        j === breakIndex
                          ? { ...breakTime, [field]: value }
                          : breakTime
                      ),
                    }
                  : period
              ),
            }
          : pg
      )
    );
  };

  const handleUpdateAllPlaygrounds = async () => {
    try {
      await Promise.all(
        playgrounds.map(async (playground) => {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/playgrounds/${playground._id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(playground),
            }
          );
          if (!response.ok) throw new Error("Network response was not ok.");
        })
      );
      alert("All playgrounds updated successfully!");
    } catch (error) {
      console.error("Error updating playgrounds:", error);
    }
  };

  const handleDeleteRejectedPlaygrounds = async () => {
    try {
      const rejectedPlaygrounds = playgrounds.filter(
        (playground) => playground.status === "rejected"
      );
      await Promise.all(
        rejectedPlaygrounds.map(async (playground) => {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/playgrounds/${playground._id}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (!response.ok) throw new Error("Network response was not ok.");
        })
      );
      setPlaygrounds(playgrounds.filter((pg) => pg.status !== "rejected"));
      alert("All rejected playgrounds deleted successfully!");
    } catch (error) {
      console.error("Error deleting playgrounds:", error);
    }
  };

  const getStatusPlaygrounds = (status) => {
    return playgrounds.filter((playground) => playground.status === status);
  };

  return (
    <div className="moderation-page">
      <div className="moderation-header">
        <h1>Moderation Page</h1>
        <button
          className="moderation-button"
          onClick={handleUpdateAllPlaygrounds}
        >
          Save All Changes
        </button>
      </div>
      <div className="playground-list">
        <div className="playground-column">
          <h2>Pending</h2>
          {getStatusPlaygrounds("pending").map((playground) => (
            <div key={playground._id} className="playground-item">
              <PlaygroundForm
                playground={playground}
                onFieldChange={handlePlaygroundChange}
                onScheduleChange={handleScheduleChange}
                onBreakChange={handleBreakChange}
              />
            </div>
          ))}
        </div>
        <div className="playground-column">
          <h2>Approved</h2>
          {getStatusPlaygrounds("approved").map((playground) => (
            <div key={playground._id} className="playground-item">
              <PlaygroundForm
                playground={playground}
                onFieldChange={handlePlaygroundChange}
                onScheduleChange={handleScheduleChange}
                onBreakChange={handleBreakChange}
              />
            </div>
          ))}
        </div>
        <div className="playground-column">
          <h2>
            Rejected{" "}
            <button
              className="moderation-button"
              onClick={handleDeleteRejectedPlaygrounds}
            >
              Delete All
            </button>
          </h2>
          {getStatusPlaygrounds("rejected").map((playground) => (
            <div key={playground._id} className="playground-item">
              <PlaygroundForm
                playground={playground}
                onFieldChange={handlePlaygroundChange}
                onScheduleChange={handleScheduleChange}
                onBreakChange={handleBreakChange}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PlaygroundForm = ({
  playground,
  onFieldChange,
  onScheduleChange,
  onBreakChange,
}) => {
  const playgroundId = playground._id;
  console.log(`Rendering PlaygroundForm for ID: ${playgroundId}`);

  return (
    <div className="playground-form">
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={playground.name}
          onChange={(e) => onFieldChange(e, playgroundId, "name")}
        />
      </div>
      <div>
        <label>Nickname:</label>
        <input
          type="text"
          value={playground.nickname}
          onChange={(e) => onFieldChange(e, playgroundId, "nickname")}
        />
      </div>
      <div>
        <label>Address:</label>
        <input
          type="text"
          value={playground.address}
          onChange={(e) => onFieldChange(e, playgroundId, "address")}
        />
      </div>
      <div>
        <label>Latitude:</label>
        <input
          type="text"
          value={playground.latitude}
          onChange={(e) => onFieldChange(e, playgroundId, "latitude")}
        />
      </div>
      <div>
        <label>Longitude:</label>
        <input
          type="text"
          value={playground.longitude}
          onChange={(e) => onFieldChange(e, playgroundId, "longitude")}
        />
      </div>
      <div>
        <label>Half Courts:</label>
        <input
          type="number"
          value={playground.halfCourts}
          onChange={(e) => onFieldChange(e, playgroundId, "halfCourts")}
        />
      </div>
      <div>
        <label>Full Courts:</label>
        <input
          type="number"
          value={playground.fullCourts}
          onChange={(e) => onFieldChange(e, playgroundId, "fullCourts")}
        />
      </div>
      <div>
        <label>Surface Type:</label>
        <select
          value={playground.surfaceType}
          onChange={(e) => onFieldChange(e, playgroundId, "surfaceType")}
        >
          <option value="Béton">Béton</option>
          <option value="Gazon">Gazon</option>
          <option value="Parquet">Parquet</option>
          <option value="Autre">Autre</option>
        </select>
      </div>
      <div>
        <label>Covered:</label>
        <select
          value={playground.covered ? "Couvert" : "Découvert"}
          onChange={(e) =>
            onFieldChange(
              { target: { value: e.target.value === "Couvert" } },
              playgroundId,
              "covered"
            )
          }
        >
          <option value="Couvert">Couvert</option>
          <option value="Découvert">Découvert</option>
        </select>
      </div>
      <div>
        <label>Additional Info:</label>
        <input
          type="text"
          value={playground.additionalInfo}
          onChange={(e) => onFieldChange(e, playgroundId, "additionalInfo")}
        />
      </div>
      <div>
        <label>Status:</label>
        <select
          value={playground.status}
          onChange={(e) => onFieldChange(e, playgroundId, "status")}
        >
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
      <div>
        <label>Open 24/7:</label>
        <input
          type="checkbox"
          checked={playground.isOpen247}
          onChange={(e) => onFieldChange(e, playgroundId, "isOpen247")}
        />
      </div>

      {!playground.isOpen247 && (
        <>
          <h2>Schedule</h2>
          {playground.schedule.map((period, periodIndex) => (
            <div key={`${playgroundId}-period-${periodIndex}`}>
              <div>
                <label>Start Day:</label>
                <select
                  value={period.startDay}
                  onChange={(e) =>
                    onScheduleChange(
                      playgroundId,
                      periodIndex,
                      "startDay",
                      e.target.value
                    )
                  }
                >
                  {daysOfWeek.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>End Day:</label>
                <select
                  value={period.endDay}
                  onChange={(e) =>
                    onScheduleChange(
                      playgroundId,
                      periodIndex,
                      "endDay",
                      e.target.value
                    )
                  }
                >
                  <option value="">None</option>
                  {daysOfWeek.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
              {period.breaks.map((breakTime, breakIndex) => (
                <div
                  key={`${playgroundId}-period-${periodIndex}-break-${breakIndex}`}
                >
                  <div>
                    <label>Break Start:</label>
                    <input
                      type="time"
                      value={breakTime.start}
                      onChange={(e) =>
                        onBreakChange(
                          playgroundId,
                          periodIndex,
                          breakIndex,
                          "start",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div>
                    <label>Break End:</label>
                    <input
                      type="time"
                      value={breakTime.end}
                      onChange={(e) =>
                        onBreakChange(
                          playgroundId,
                          periodIndex,
                          breakIndex,
                          "end",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ModerationPage;
