import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 48.8566, // Default to Paris coordinates
  lng: 2.3522,
};

function PlaygroundMap({ onMarkerClick }) {
  const [playgrounds, setPlaygrounds] = useState([]);
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDa6fpfYDkoDMqtXen14ZLNrUYkIRs_k04",
  });

  useEffect(() => {
    if (isLoaded && map) {
      fetchPlaygrounds();
    }
  }, [isLoaded, map]);

  const fetchPlaygrounds = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/playgrounds`
      );
      const data = await response.json();
      const approvedPlaygrounds = data.filter(
        (playground) => playground.status === "approved"
      );
      setPlaygrounds(approvedPlaygrounds);
      createMarkers(approvedPlaygrounds);
    } catch (error) {
      console.error("Failed to fetch playgrounds:", error);
    }
  };

  const handleMarkerClick = (playground, index) => {
    onMarkerClick(playground, index); // Now passes both playground and index
  };

  const createMarkers = (playgrounds) => {
    playgrounds.forEach((playground, index) => {
      const position = new window.google.maps.LatLng(
        playground.latitude,
        playground.longitude
      );
      new CustomMarker(
        position,
        playground,
        map,
        index,
        () => handleMarkerClick(playground, index) // Passing index along with playground
      );
    });
  };

  class CustomMarker extends window.google.maps.OverlayView {
    constructor(position, playground, map, index, onClick) {
      super();
      this.position = position;
      this.playground = playground;
      this.map = map;
      this.index = index; // Store index if needed for further operations
      this.onClick = () => onClick(); // Make sure to call onClick correctly
      this.setMap(map);
      this.isOpen = this.computeOpenStatus(); // Determine if open or closed
    }

    computeOpenStatus() {
      if (this.playground.isOpen247) {
        return true; // Always open if it's marked as open 24/7
      }

      const now = new Date();
      const dayNames = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      const todayName = dayNames[now.getDay()];
      const currentTime = now.getHours() + now.getMinutes() / 60;

      let todaysSchedule = this.playground.schedule.find((period) => {
        const startIdx = dayNames.indexOf(period.startDay);
        const endIdx = dayNames.indexOf(
          period.endDay.length > 0 ? period.endDay : period.startDay
        );
        return startIdx <= endIdx
          ? now.getDay() >= startIdx && now.getDay() <= endIdx
          : now.getDay() >= startIdx || now.getDay() <= endIdx;
      });

      if (
        !todaysSchedule ||
        !todaysSchedule.breaks ||
        todaysSchedule.breaks.length === 0
      ) {
        return false; // Closed today
      }

      // Check if current time is within any break periods
      return todaysSchedule.breaks.some((breakTime) => {
        const breakStart = parseFloat(
          breakTime.start
            .split(":")
            .reduce(
              (acc, time, idx) => acc + parseInt(time, 10) / (idx ? 60 : 1),
              0
            )
        );
        const breakEnd = parseFloat(
          breakTime.end
            .split(":")
            .reduce(
              (acc, time, idx) => acc + parseInt(time, 10) / (idx ? 60 : 1),
              0
            )
        );
        return currentTime >= breakStart && currentTime < breakEnd;
      });
    }

    onAdd() {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.cursor = "pointer";
      const isOpen = this.isOpen; // Assume `isOpen` is a boolean

      const openSVG = isOpen
        ? `<svg class="marker__circle" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 30.6667C24.1002 30.6667 30.6667 24.1002 30.6667 16C30.6667 7.89982 24.1002 1.33333 16 1.33333C7.89982 1.33333 1.33333 7.89982 1.33333 16C1.33333 24.1002 7.89982 30.6667 16 30.6667ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="url(#paint0_linear_230_29)"/>
      <defs>
      <linearGradient id="paint0_linear_230_29" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
      <stop stop-color="#00FF38"/>
      <stop offset="1" stop-color="#00FF38" stop-opacity="0"/>
      </linearGradient>
      </defs>
      </svg>
      `
        : "";

      div.innerHTML = `<div class="marker"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" fill="white"/>
      <path d="M17.1785 18.6687C16.6193 18.3952 15.7088 18.3952 15.1496 18.6687C12.7533 19.8635 10.5008 20.1802 9.2707 19.59C9.25473 19.5756 9.2707 19.6476 9.30265 19.6907C10.1334 20.7848 11.4433 21.4758 12.929 21.7637C12.961 21.7781 12.977 21.7493 12.945 21.7205C12.6894 21.4902 12.4498 21.2598 12.1942 21.0151C12.0983 20.9144 12.0824 20.8136 12.1782 20.7848C13.2965 20.5545 14.5426 20.0938 15.8206 19.3741C16.0123 19.2733 16.3158 19.2733 16.4915 19.3741C17.7536 20.0938 18.9997 20.5545 20.102 20.7848C20.1978 20.7992 20.1818 20.9144 20.086 21.0151C19.8464 21.2598 19.5908 21.5046 19.3352 21.7205C19.3032 21.7493 19.3192 21.7781 19.3511 21.7637C20.8528 21.4758 22.1468 20.7992 22.9935 19.7051C23.0254 19.6764 23.0414 19.6044 23.0254 19.6044C21.8113 20.1658 19.5588 19.8635 17.1785 18.6687Z" fill="black"/>
      <path d="M14.7822 22.0949C14.7822 22.1813 14.6544 22.2245 14.5107 22.1957C14.1592 22.1093 13.8237 22.0085 13.4882 21.8934C13.4403 21.879 13.4243 21.8934 13.4403 21.9222C14.2231 23.0306 15.0538 24.0383 15.9005 24.9452C15.9325 24.974 15.9964 24.9596 15.9964 24.9308C15.9964 23.2033 15.9964 21.4759 15.9964 19.7341C15.581 19.9788 15.1816 20.1947 14.7663 20.3818C14.7822 20.9577 14.7822 21.5191 14.7822 22.0949Z" fill="black"/>
      <path d="M18.9039 21.8499C18.5684 21.9795 18.2329 22.0802 17.8974 22.1666C17.7536 22.1954 17.6418 22.1522 17.6418 22.0659C17.6418 21.49 17.6418 20.9286 17.6418 20.3528C17.2424 20.1657 16.8271 19.9497 16.4117 19.7194C16.4117 21.4468 16.4117 23.1743 16.4117 24.9161C16.4117 24.9449 16.4756 24.9593 16.5076 24.9305C17.3383 24.0236 18.169 23.0159 18.9518 21.8931C18.9678 21.8643 18.9518 21.8355 18.9039 21.8499Z" fill="black"/>
      <path d="M14.8142 17.2002C12.4498 15.4295 10.2452 12.7664 9.12695 9.22511C9.11098 9.16753 9.07902 9.13874 9.07902 9.18193C9.2867 10.5495 9.52633 11.8739 9.79791 13.1838C9.79791 13.2126 9.82986 13.2702 9.86182 13.2702C10.0056 13.2702 10.1494 13.2558 10.2931 13.2414C10.3571 13.2414 10.4689 13.3422 10.5488 13.4574C11.4593 15.0121 12.5776 16.3364 13.7917 17.4017C13.9675 17.56 13.9675 17.7472 13.7917 17.8048C12.5776 18.251 11.4434 18.4526 10.5328 18.4094C10.4529 18.4094 10.3411 18.2942 10.2772 18.1647C10.1334 17.848 9.98962 17.5313 9.84584 17.2002C9.82986 17.157 9.79791 17.1282 9.78194 17.1426C9.51036 17.7904 9.27073 18.395 9.07902 18.9852C9.07902 18.9996 9.095 19.086 9.12695 19.086C10.2452 19.7481 12.4498 19.5322 14.8301 18.4094C15.3733 18.1503 15.3733 17.6032 14.8142 17.2002Z" fill="black"/>
      <path d="M9.82987 16.8406C9.79792 16.5959 9.78195 16.3656 9.76597 16.1208C9.74999 16.0201 9.82987 16.0345 9.90975 16.1352C10.5168 16.8262 11.2517 17.3876 12.0824 17.8051C12.4338 17.7331 12.8173 17.6467 13.2007 17.5316C10.5168 16.6391 8.37612 14.4654 8.36014 11.0105C8.36014 10.9529 8.36014 10.9673 8.36014 11.0393C8.36014 13.2849 8.91928 15.2139 9.79792 16.8118C9.79792 16.855 9.82987 16.8694 9.82987 16.8406Z" fill="black"/>
      <path d="M8.34416 10.8089C8.37611 14.2494 10.5328 16.4087 13.2166 17.3013C12.8332 16.927 12.4658 16.5239 12.0984 16.1064C11.2676 15.689 10.5328 15.1419 9.92571 14.451C9.82986 14.3502 9.76596 14.1918 9.78194 14.1055C9.79791 13.9183 9.82986 13.7456 9.86181 13.5585C9.86181 13.5297 9.82986 13.4865 9.81389 13.4721C8.91927 12.8819 8.36013 11.9606 8.36013 10.7226C8.34416 10.6794 8.34416 10.737 8.34416 10.8089Z" fill="black"/>
      <path d="M22.4823 17.2004C22.3386 17.5315 22.1948 17.8482 22.051 18.1649C21.9871 18.2944 21.8753 18.4096 21.7954 18.4096C20.8848 18.4528 19.7665 18.2512 18.5524 17.805C18.3767 17.733 18.3767 17.5603 18.5524 17.4019C19.7665 16.3223 20.9008 14.9979 21.8114 13.4288C21.8912 13.2992 22.0031 13.1985 22.067 13.1985C22.2107 13.2129 22.3545 13.2272 22.4983 13.2272C22.5143 13.2272 22.5462 13.1841 22.5622 13.1409C22.8338 11.8309 23.0734 10.5065 23.2651 9.13896C23.2651 9.09577 23.2491 9.12456 23.2172 9.18214C22.0989 12.7378 19.8943 15.4154 17.514 17.186C16.9549 17.5891 16.9549 18.1361 17.514 18.4096C19.8784 19.518 22.0829 19.7484 23.2012 19.0862C23.2172 19.0718 23.2491 18.9998 23.2491 18.9854C23.0415 18.3952 22.8018 17.7906 22.5303 17.1428C22.5303 17.1284 22.4983 17.1716 22.4823 17.2004Z" fill="black"/>
      <path d="M24 10.7223C24 11.9747 23.4408 12.8961 22.5622 13.4863C22.5462 13.5007 22.5143 13.5582 22.5143 13.5726C22.5462 13.7598 22.5622 13.9325 22.5782 14.1197C22.5941 14.1916 22.5143 14.35 22.4344 14.4652C21.8273 15.1561 21.0925 15.7176 20.2617 16.135C19.9103 16.5669 19.5269 16.9699 19.1435 17.3442C21.8273 16.4517 23.968 14.278 23.984 10.8231C24 10.7511 24 10.6792 24 10.7223Z" fill="black"/>
      <path d="M24 11.0969C23.968 14.5374 21.8113 16.6967 19.1275 17.5892C19.5109 17.7044 19.8783 17.8051 20.2458 17.8627C21.0765 17.4452 21.8113 16.8982 22.4184 16.2072C22.5142 16.1065 22.5782 16.0921 22.5622 16.1928C22.5462 16.4376 22.5142 16.6679 22.4823 16.9126C22.4823 16.9414 22.5143 16.927 22.5302 16.8838C23.4248 15.2859 23.984 13.3713 23.984 11.1257C24 11.0393 24 11.0249 24 11.0969Z" fill="black"/>
      <path d="M15.1496 17.0419C15.7088 17.445 16.6194 17.445 17.1785 17.0419C19.5748 15.3433 21.8273 12.7521 23.0574 9.28281C23.0734 9.22523 23.0574 9.22523 23.0255 9.26841C22.1948 10.8951 20.8848 12.1187 19.3991 12.9248C19.3671 12.9392 19.3511 12.9824 19.3831 12.9824C19.6387 13.0256 19.8783 13.0544 20.1339 13.0688C20.2298 13.0688 20.2458 13.1696 20.1499 13.2703C19.0316 14.5803 17.7856 15.6312 16.5075 16.4373C16.3158 16.5525 16.0123 16.5525 15.8366 16.4373C14.5745 15.6312 13.3285 14.5947 12.2262 13.2991C12.1303 13.1984 12.1463 13.0976 12.2421 13.0976C12.4818 13.0832 12.7374 13.0544 12.993 13.0112C13.0249 13.0112 13.0089 12.968 12.977 12.9536C11.4753 12.1475 10.1813 10.9239 9.33462 9.3116C9.30267 9.25402 9.2867 9.26841 9.30267 9.326C10.5168 12.7809 12.7693 15.3577 15.1496 17.0419Z" fill="black"/>
      <path d="M17.5619 13.5151C17.5619 13.4287 17.6897 13.3424 17.8335 13.328C18.1849 13.2848 18.5204 13.2416 18.8559 13.1552C18.9038 13.1408 18.9198 13.112 18.9038 13.0976C18.121 12.4642 17.2903 11.7157 16.4436 10.852C16.4117 10.8232 16.3478 10.8376 16.3478 10.8664C16.3478 12.5938 16.3478 14.3212 16.3478 16.0631C16.7631 15.804 17.1625 15.5305 17.5779 15.2282C17.5619 14.6667 17.5619 14.0909 17.5619 13.5151Z" fill="black"/>
      <path d="M13.4243 13.1551C13.7597 13.2271 14.0952 13.2847 14.4307 13.3279C14.5745 13.3423 14.6863 13.4287 14.6863 13.515C14.6863 14.0908 14.6863 14.6523 14.6863 15.2281C15.0857 15.5304 15.5011 15.8183 15.9164 16.0774C15.9164 14.35 15.9164 12.6225 15.9164 10.8807C15.9164 10.8519 15.8525 10.8375 15.8206 10.8663C14.9898 11.7156 14.1591 12.4498 13.3763 13.0832C13.3604 13.112 13.3923 13.1551 13.4243 13.1551Z" fill="black"/>
      </svg>
      ${openSVG}
      </div>`;
      div.addEventListener("click", this.onClick); // Ensuring onClick is correctly bound
      this.div = div;
      this.getPanes().overlayMouseTarget.appendChild(div);
    }

    draw() {
      const overlayProjection = this.getProjection();
      const sw = overlayProjection.fromLatLngToDivPixel(this.position);
      const div = this.div;
      div.style.left = `${sw.x}px`;
      div.style.top = `${sw.y}px`;
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  }

  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#000000",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#7f7f7f",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#c4c4c4",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 21,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#7f7f7f",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#7f7f7f",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#575757",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#2c2c2c",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#999999",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
      ],
    },
  ];

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={11.5}
      onLoad={(map) => setMap(map)}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    />
  );
}

export default PlaygroundMap;
