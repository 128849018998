import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { LoadScript } from "@react-google-maps/api";
import PlaygroundForm from "./PlaygroundForm";
import PlaygroundMap from "./PlaygroundMap";
import About from "./About";
import PlaygroundDetails from "./PlaygroundDetails";
import ModerationPage from "./ModerationPage"; // Import the ModerationPage component
import logo from "./assets/icons/logo.svg";

function App() {
  const [activeSection, setActiveSection] = useState(null);
  const [selectedPlayground, setSelectedPlayground] = useState(null);

  const handlePlusClick = () => {
    const plusElement = document.querySelector(".header__plus");
    if (plusElement.classList.contains("header__plus--cross")) {
      setActiveSection(null);
    } else {
      setActiveSection("form");
      setSelectedPlayground(null); // Reset selected playground when switching to form
    }
  };

  const handleAboutClick = () => {
    if (activeSection === "about") {
      setActiveSection(null);
    } else {
      setActiveSection("about");
    }
  };

  const handleMarkerClick = (playground, index) => {
    console.log("Marker clicked:", playground, "Index:", index);
    setSelectedPlayground({ data: playground, index: index });
    setActiveSection("details");
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDa6fpfYDkoDMqtXen14ZLNrUYkIRs_k04"
      libraries={["places"]}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <div className="hub">
                  <header className="header">
                    <div
                      className={`header__plus ${
                        activeSection ? "header__plus--cross" : ""
                      }`}
                      onClick={handlePlusClick}
                    ></div>
                    <img src={logo} className="header__logo" alt="logo" />
                    <div className="header__about" onClick={handleAboutClick}>
                      i
                    </div>
                  </header>
                  <div className="hub__container">
                    {activeSection === "form" && <PlaygroundForm />}
                    {activeSection === "about" && <About />}
                    {activeSection === "details" && selectedPlayground && (
                      <PlaygroundDetails
                        playground={selectedPlayground.data}
                        index={selectedPlayground.index}
                      />
                    )}
                  </div>
                </div>
                <div className="map">
                  <PlaygroundMap onMarkerClick={handleMarkerClick} />
                </div>
              </div>
            }
          />
          <Route path="/moderation" element={<ModerationPage />} />
        </Routes>
      </Router>
    </LoadScript>
  );
}

export default App;
