import React from "react";

const IncrementDecrementInput = ({ label, name, value, onChange, error }) => {
  const handleIncrement = () => {
    onChange({ target: { name, value: value + 1 } });
  };

  const handleDecrement = () => {
    if (value > 0) {
      onChange({ target: { name, value: value - 1 } });
    }
  };

  return (
    <div className="form__increment">
      <div className="form__increment--buttons">
        <button
          className="form__increment--minus"
          type="button"
          onClick={handleDecrement}
        >
          -
        </button>
        <span>{value}</span>
        <button
          className="form__increment--plus"
          type="button"
          onClick={handleIncrement}
        >
          +
        </button>
      </div>
      <label className="form__increment--label">{label}</label>
      {error && <div>{error}</div>}
    </div>
  );
};

export default IncrementDecrementInput;
